import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Layout from "../components/Layout";
import Header from "../components/Header";
import MobileMenu from "../components/MobileMenu";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import cross from "../assets/images/cross.jpg"


const BlogPostPage = () => (
  <Layout pageTitle="Apiton | Blog Post Page">
    {/* <SEO title={post.frontmatter.title} description={post.excerpt} /> */}


    <Header btnClass="main-nav__btn-two" />
    <MobileMenu />
    <PageHeader pageHeading="PRIVACY POLICY" breadcrumb="1" align="text-center" />

    <section className="blog-details">
      <Container>
        <Row>
          <Col lg={12} xs={12}>

            <p>We value your trust &amp; respect your privacy, that’s why we treat your data just like we treat our own – more responsibly &amp; we are committed to keep your data safe.</p>

            <br />

            <div className="row">
              <div className="col-2">
                <img src={cross} />
              </div>
              <div className="col middle-center block">
                <div>
                  <p><b>You Own Your Data; Not Us</b></p>

                  <p><b>We Will Never Do Advertisements</b></p>

                  <p><b>We Will Never Sell Your Data</b></p>
                </div>
              </div>
            </div>
            <br />

            <p><strong>Introduction</strong></p>

            <p>We at Megam Technologies are committed to respecting your online privacy and recognize your need for appropriate protection and management of any personally identifiable data you share with us. Our Privacy Policy tells you what information we do collect from you, what we do with it, who can access it and what you can do about it.</p>

            <br />

            <p><strong>What Information We Collect</strong></p>

            <p>We only collect the information that we actually need. Some of that is information that you actively give us when you sign up for an account, register for an event, ask for customer support, or buy something from us. We store your name and contact information, but we don't store credit card numbers (except and in with your permission one of our secured payment gateways).</p>

            <p>When you visit one of our websites or use our software, we automatically log some basic information like how you got to the site, where you navigated within it, and what features and settings you use. We use this information to improve our websites and services and to drive new product development.</p>

            <p>Sometimes we receive information indirectly. If you engage with our brand on social media (for instance, liking, commenting, retweeting, mentioning, or following us), we'll have access to your interactions and profile information. We'll still have that information even if you later remove it from the social media site.</p>
            <br />

            <p><strong>What We Do With Your Information</strong></p>

            <p>We use your information to provide the services you've requested create and maintain your accounts, and keep an eye out for unauthorized activity on your accounts. We also use it to communicate with you about the products you're currently using, your customer support requests, new products you may like, chances for you to give us feedback, and policy updates. We analyze the information we collect to understand user needs and to improve our websites and services.</p>

            <p>We're required to have a legal basis for collecting and processing your information. In most cases, we either have your consent or need the information to provide the service you've requested from us. When that's not the case, we must demonstrate that we have another legal basis, such as our legitimate business interests.</p>

            <p>You can decline certain kinds of information use either by not providing the information in the first place or by opting out later. You can also disable cookies to prevent your browser from giving us information, but if you do so, certain website features may not work properly. We completely disable third-party cookies from all Megam Technologies websites and products.</p>

            <p>We limit access to your personal information to our employees and partners who have a legitimate need to use it. If we share your information with other parties (like developers, service providers, domain registrars, and reselling partners), they must have appropriate security measures and a valid reason for using your information, typically to serve you.</p>

            <p>We keep your personal information for as long as it is required for the purposes stated in this Privacy Policy. When we no longer have a legitimate need to process your information, we will delete, anonymize, or isolate your information, whichever is appropriate</p>
            <br />

            <p><strong>Who we share your information with</strong></p>

            <p>Megam Technologies by far &amp; large have access to all your information &amp; We do not sell any personal information of yours. We share your information only in the ways that are described in this Privacy Policy, and only with parties who adopt appropriate confidentiality and security measures.</p>
            <br />

            <p > <strong>Your rights with respect to information we hold about you as a controller</strong></p>

            <p>Megam Technologies undertakes to provide you the following rights to you as the controller</p>

            <p><em>Right to Access </em>: You have the right to access (and obtain a copy of, if required) the categories of personal information that we hold about you, including the information's source, purpose and period of processing, and the persons to whom the information is shared</p>

           

            <p><em>Right to Rectification </em>: You have the right to update the information we hold about you or to rectify any inaccuracies. Based on the purpose for which we use your information, you can instruct us to add supplemental information about you in our database</p>

           

            <p><em>Right to Erasure </em>: You have the right to request that we delete your personal information in certain circumstances, such as when it is no longer necessary for the purpose for which it was originally collected</p>

            

            <p><em>Right To Restriction Of Processing </em>: You may also have the right to request to restrict the use of your information in certain circumstances, such as when you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it</p>


            <p><em>Right To Data Portability </em>: You have the right to transfer your information to a third party in a structured, commonly used and machine-readable format, in circumstances where the information is processed with your consent or by automated means</p>


            <p><em>Right To Object </em>: You have the right to object to the use of your information in certain circumstances, such as the use of your personal information for direct marketing</p>


            <p><em>Right To Complain </em>: You have the right to complain to the appropriate supervisory authority if you have any grievance against the way we collect, use or share your information. This right may not be available to you if there is no supervisory authority dealing with data protection in your country.</p>
          </Col>

        </Row>
      </Container>
    </section>

    <Footer />
  </Layout>
);
// export const query = graphql`
//   query($slug: String!) {
//     markdownRemark(fields: { slug: { eq: $slug } }) {
//       html
//       frontmatter {
//         title
//       }
//       excerpt
//     }
//   }`

export default BlogPostPage;
